<template>
    <div>
        <div
            id="recaptcha"
            class="g-recaptcha"
            :data-sitekey="siteKey"
            data-action="checkout"
            @click="clearError"
        ></div>
        <p v-if="error" class="error">{{ error }}</p>
    </div>
</template>

<script>
export default {
    props: {
        siteKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            error: null,
        }
    },
    mounted() {
        this.renderRecaptcha()
    },
    methods: {
        renderRecaptcha() {
            if (window.grecaptcha.enterprise) {
                window.grecaptcha.enterprise.render('recaptcha', {
                    sitekey: this.siteKey,
                })
            } else {
                this.error = 'The reCAPTCHA client is not available.'
            }
        },
        async getToken() {
            const token = await window.grecaptcha.enterprise.getResponse()
            if (!token) {
                this.error = 'Please complete the reCAPTCHA.'
                return null
            }
            return token
        },
        clearError() {
            this.error = null
        },
    },
}
</script>

<style>
.error {
    background: red;
    color: white;
    font-size: 14px;
}
</style>
