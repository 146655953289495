<template>
    <v-app>
        <v-main class="mx-12 my-8">
            <!-- STEP 6 -->
            <div
                v-if="
                    data && data.user && data.user.clockOut && data.user.clockIn
                "
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <v-icon color="primary" class="mb-2">mdi-check-decagram</v-icon>
                <h2 class="text-capitalize">{{ data.user.name }}</h2>
                <p class="mt-4">
                    time registration has already taken place today with a total
                    of:
                    <strong>
                        {{ (data.user.totalTime / 3600).toFixed(1) }}
                        hours</strong
                    >
                    in <strong>{{ data.user.totalSessions }} sessions</strong>
                </p>
                <v-btn
                    color="primary"
                    class="mt-4"
                    @click="setClockInView"
                    :loading="loading"
                    v-if="data.user.selectedLocation"
                >
                    MAKE A NEW REGISTER
                </v-btn>
            </div>
            <!-- STEP 6 -->
            <div
                v-if="finished"
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <v-icon color="primary" class="mb-2">mdi-check-decagram</v-icon>
                <h2 class="text-capitalize">{{ data.user.name }}</h2>
                <p class="mt-4">
                    The {{ !data.user.clockIn ? 'Clock In' : 'Clock Out' }} has
                    been successfully saved
                </p>
                <strong
                    class="mt-0"
                    v-if="data.user.clockIn && !data.user.clockOut"
                >
                    Total:
                    {{
                        (
                            (localClockOut / 1000 -
                                data.user.clockIn._seconds) /
                            3600
                        ).toFixed(1)
                    }}
                    hours
                </strong>
                <v-btn
                    color="primary"
                    class="mt-4"
                    @click="setEmailView"
                    :loading="loading"
                >
                    Go Back
                </v-btn>
            </div>
            <!-- STEP 1 -->
            <div v-else-if="!data">
                <v-row class="my-5 d-flex align-center justify-center">
                    <h2>Validating Information</h2>
                </v-row>

                <v-progress-linear
                    color="primary"
                    buffer-value="0"
                    stream
                ></v-progress-linear>
            </div>
            <!-- STEP 2 -->
            <div
                v-else-if="data && !userId"
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <h2 class="text-capitalize">
                    Project: {{ data.project.name }}
                </h2>
                <p class="mt-4">
                    To access the timesheet registration of the project, please
                    enter your e-mail address
                </p>
                <v-form v-model="valid">
                    <v-text-field
                        v-model="userEmail"
                        hide-details
                        label="E-Mail"
                        prepend-inner-icon="mdi-email"
                        outlined
                        :rules="[rules.email]"
                        style="min-width: 300px"
                    />
                </v-form>
                <v-row class="mt-4 mb-1">
                    <div>
                        <Recaptcha :siteKey="siteKey" ref="recaptcha" />
                    </div>
                </v-row>
                <v-btn
                    color="primary"
                    :disabled="
                        !valid ||
                            realLocation.lat == null ||
                            realLocation.lat == undefined
                    "
                    class="mt-3"
                    @click="getExternalUserByEmail"
                    :loading="loading"
                >
                    VERIFY
                </v-btn>
                <v-row
                    no-gutter
                    v-if="
                        realLocation.lat == null ||
                            realLocation.lat == undefined
                    "
                    class="my-4"
                >
                    <p class="red--text">
                        Please let the browser know your location.
                    </p>
                </v-row>
            </div>
            <!-- STEP 3 -->
            <div
                v-else-if="data.user && !data.user.active"
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <h2 class="text-capitalize">{{ data.user.name }}</h2>
                <p class="mt-4">
                    Your user is disabled. For more information, please contact
                    the project leader.
                </p>
                <v-btn color="primary" class="mt-4" @click="setEmailView()">
                    Go Back
                </v-btn>
            </div>
            <!-- STEP 4 -->
            <div
                v-else-if="data.user && !data.user.selectedLocation"
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <v-icon color="error" class="mb-2"
                    >mdi-map-marker-radius</v-icon
                >
                <h2 class="text-capitalize">{{ data.user.name }}</h2>
                <p class="mt-4" style="max-width: 70vw;">
                    It is not possible to log in from your current location,
                    remember that you must be in one of the project locations to
                    continue, please check that you do not have the location
                    active on your device and that you are not using VPN
                    services.
                </p>
                <v-row class="mt-1">
                    <v-btn
                        color="white"
                        class="mt-4 mr-3"
                        @click="setEmailView()"
                    >
                        Go Back
                    </v-btn>
                </v-row>
            </div>
            <div
                v-else-if="
                    data.user &&
                        !data.code &&
                        (!data.user.clockIn || !data.user.clockOut)
                "
                class="d-flex flex-column align-center justify-center text-center my-5"
            >
                <h2 class="text-capitalize">Welcome, {{ data.user.name }}</h2>
                <p class="mt-4 mb-1">
                    <v-icon>mdi-map-marker</v-icon>Location:
                    <strong>{{ data.user.selectedLocation.name }}</strong>
                </p>
                <p class="mt-4 mb-1">
                    <v-icon> mdi-office-building-marker</v-icon>Project:
                    <strong>{{ data.project.name }}</strong>
                </p>
                <div v-if="!data.user.clockIn">
                    <p class="mt-4 mb-1">
                        Please select the scope you are working on.
                    </p>
                    <div
                        cols="12"
                        v-for="quote of data.project.quotes"
                        :key="quote.id"
                        class="mt-4 mb-4"
                    >
                        <v-data-table
                            :headers="headers"
                            :items="quote.costs"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="{ 'max-width': '960px', width: '90vw' }"
                        >
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex justify-center my-4">
                                    <v-simple-checkbox
                                        color="green"
                                        v-model="item.selected"
                                        @click="onCheckboxClicked(quote, item)"
                                        :disabled="disabledCheckBox(item)"
                                    ></v-simple-checkbox>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
                <v-text-field
                    v-model="password"
                    hide-details
                    label="Password"
                    type="password"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    :style="{ 'max-width': '360px', width: '90vw' }"
                />
                <v-row class="mt-1">
                    <v-btn
                        color="white"
                        class="mt-4 mr-3"
                        @click="setEmailView()"
                    >
                        Go Back
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="
                            !password ||
                                (!data.user.clockIn &&
                                    selectedCost.quote == undefined)
                        "
                        class="mt-4"
                        @click="validatePassword"
                        :loading="loading"
                    >
                        {{
                            !data.user.clockIn
                                ? 'SET CLOCK IN'
                                : 'SET CLOCK OUT'
                        }}
                    </v-btn>
                </v-row>
            </div>
        </v-main>
        <Footer ref="footer" />
        <!--Error Alert-->
        <Errors />
    </v-app>
</template>

<script>
import API from '@/services/api'
import Errors from '@/components/Layout/Errors'
import { mapMutations } from 'vuex'
import Footer from '@/components/Layout/Footer'
import { auth } from '@/services/firebase'
import _ from 'lodash'
import Recaptcha from '../Layout/Recaptcha.vue'

export default {
    name: 'Timesheets',
    components: {
        Errors,
        Footer,
        Recaptcha,
    },
    data: () => ({
        siteKey: process.env.VUE_APP_RECAPTCHA_TIMESHEETS_KEY,
        password: null,
        loading: false,
        user: '',
        data: undefined,
        verifyCode: '',
        userEmail: null,
        companyId: undefined,
        projectId: undefined,
        userId: undefined,
        valid: false,
        finished: false,
        rules: {
            required: v => !!v || 'Required',
            number: v => v > 0 || 'Not valid',
            email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
        },
        realLocation: { lat: null, lng: null },
        locationOptions: {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        },
        localClockOut: null,
        headers: [
            {
                text: 'index',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'scope',
                value: 'reference',
                sortable: false,
                align: 'left',
            },
            {
                text: 'actions',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        selectedCost: {},
    }),
    created() {
        auth().onAuthStateChanged(user => {
            if (!user) {
                auth()
                    .signInAnonymously()
                    .then(userCredential => {
                        this.user = userCredential
                    })
                    .catch(error => {
                        console.error('Error in authentication:', error.message)
                    })
            } else {
                this.validateHash()
            }
        })
    },
    async mounted() {
        this.getLocation()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        disabledCheckBox(item) {
            const quotes = this.data.project.quotes.filter(quote => {
                const selectedCost = quote.costs.find(cost => cost.selected)
                if (selectedCost && selectedCost.id == item.id) {
                    return false
                }
                return !!selectedCost
            })
            return quotes.length >= 1
        },
        onCheckboxClicked(quote, cost) {
            if (cost.selected) {
                this.selectedCost = {
                    quote: { id: quote.id, name: quote.name },
                    cost,
                }
            } else {
                this.selectedCost = {}
            }
        },
        async getLocation() {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            this.successLocation(position)
                        },
                        error => {
                            this.setErrorItems({
                                source: this.$options.name,
                                message: `Error al obtener la ubicación: ${error.message}`,
                            })
                        },
                        this.locationOptions
                    )
                } else {
                    this.setErrorItems({
                        source: this.$options.name,
                        message:
                            'La geolocalización no está soportada por este navegador.',
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: `Excepción al intentar obtener la ubicación: ${error.message}`,
                })
            }
        },
        successLocation(pos) {
            this.realLocation = {
                lat: pos.coords.latitude,
                long: pos.coords.longitude,
                accuracy: pos.coords.accuracy,
            }
        },
        async validateHash() {
            try {
                this.data = await API.validateHash(
                    this.$router.currentRoute.query.request
                )
                if (this.data.companyId) {
                    this.companyId = this.data.companyId
                }
                if (this.data.projectId) {
                    this.projectId = this.data.projectId
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getExternalUserByEmail() {
            try {
                this.loading = true
                const token = await this.$refs.recaptcha.getToken()
                if (!token) {
                    throw new Error('Error getting token')
                }
                this.data.user = await API.getExternalUserByEmail({
                    companyId: this.companyId,
                    projectId: this.projectId,
                    email: this.userEmail,
                    lat: this.realLocation.lat,
                    long: this.realLocation.long,
                    recaptchaToken: token,
                })
                this.userId = this.data.user.id
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async validatePassword() {
            try {
                this.loading = true
                const code = await API.validateExternalUserPassword({
                    companyId: this.companyId,
                    projectId: this.projectId,
                    userId: this.userId,
                    password: this.password,
                    lat: this.realLocation.lat,
                    long: this.realLocation.long,
                    selectedCost: this.selectedCost,
                })
                this.data.code = code
                this.finished = true
                this.localClockOut = Date.now()
                this.selectedCost = {}
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        setEmailView() {
            // this.userId = null
            // this.finished = false
            // this.data.user = null
            // this.verifyCode = ''
            // delete this.data.user
            // delete this.data.code
            this.$router.go()
        },
        setClockInView() {
            const d = this.data
            delete d.code
            delete d.user.clockIn
            delete d.user.clockOut
            this.data = _.cloneDeep(d)
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}

.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.uppercase {
    text-transform: uppercase;
    font-size: 23px;
    font-weight: bold;
    white-space: normal;
    overflow-wrap: break-word;
    width: 100%;
}
.no-break {
    overflow-wrap: normal;
    word-break: keep-all;
}
.custom-font {
    font-size: 22px;
}
h1 {
    margin-top: 20px;
    font-size: 24px;
    color: #333;
}
h2 p {
    color: #666;
    font-size: 18px;
}
.notes {
    font-weight: normal;
}

.custom-margin {
    margin: 20px;
}
.scrollable-div {
    overflow-x: auto;
    white-space: nowrap;
}

.no-hover-row {
    background-color: transparent;
}

.no-hover-row:hover {
    background-color: transparent !important;
    cursor: default !important;
}

.no-hover-row td {
    border: none;
}
</style>
